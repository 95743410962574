import React from 'react';
import BlogRollComponent from '../components/blog-roll';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Blog = () => {
    return (
        <Layout>
            <SEO />
            <BlogRollComponent />
        </Layout>
    );
};

export default Blog;