import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';

interface Props {
    data: {
        allMarkdownRemark: {
            edges: {
                node: {
                    id: string;
                    frontmatter: {
                        featuredPost: boolean;
                        path: string;
                        title: string;
                        date: string;
                    }
                    excerpt: string;
                }
            }[]
        }
    }
}

const BlogRoll = ({ data }: Props) => {
    console.log('data', data)
    const { edges } = data.allMarkdownRemark;
    return (
        <div className={'columns is-multiline'}>
            {edges &&
                edges.map(({ node: post }) => {
                    return (
                        <article
                            key={post.id}
                            className={`blog-list-item tile is-child box notification is-featured`}
                        >
                            <header>
                                <p className={'post-meta'}>
                                    <Link
                                        to={post.frontmatter.path}
                                        className={'title has-text-primary is-size-4'}
                                    >
                                        {post.frontmatter.title}
                                    </Link>
                                    <span> &bull; </span>
                                    <span className="subtitle is-size-5 is-block">
                                        {post.frontmatter.date}
                                    </span>
                                </p>
                            </header>
                            <p>
                                {post.excerpt}
                                <br />
                                <br />
                                <Link className="button" to={post.frontmatter.path}>
                                    Keep Reading →
                            </Link>
                            </p>
                        </article>
                    )
                }
                )}
        </div>
    )
}

const BlogRollComponent = () => (
    <StaticQuery
        query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            filter: { frontmatter: {template: { eq: "blog"}} },
            sort: { order: DESC, fields: [frontmatter___date]}
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                frontmatter {
                  path
                  title
                  date(formatString: "MMMM DD, YYYY")
                }
              }
            }
          }
        }
    `}
        render={(data) => (<BlogRoll data={data} />)}
    />
)

export default BlogRollComponent;